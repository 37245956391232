<template>
	<div id="content">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="box">
						<div class="row">
							<label class="col col-form-label" for="horse_cats">{{ $t('horse.cat_cheval')}}</label>
							<label class="col col-form-label" for="residence">{{ $t('global.residences')}}</label>
							<label class="col col-form-label" for="actestype_id">{{ $t('global.actes_types')}}</label>
						</div>
						<div class="row">
							<div class="col">
								<e-select
									id="horse_cats"
									class="mb-3"
									v-model="categorie_selected"
									track-by="categorie_id"
									label="categorie_libelle"
									:placeholder="$t('horse.categorie_cheval')"
									:options="all_categorie"
									:searchable="true"
									:allow-empty="true"
									:loading="load_categorie"
									:multiple="true"
									:show-labels="false"
								>
									<template slot="noOptions">{{ $t('categorie.aucune_categorie') }}</template>
								</e-select>
							</div>
							<div class="col">
								<e-select
									id="residence"
									v-model="residence_selected"
									:options="residences"
									:searchable="true"
									label="lieu_label"
									:placeholder="$t('monte.choix_lieu')"
									:loading="laoding_residence"
								>	
									<template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
							<div class="col">
								<e-select
									v-model="acte_type_selected"
									id="actestype_id"
									track-by="actestype_id"
									label="actestype_label"
									:placeholder="$t('acte.selectionnez_acte')"
									:selectedLabel="$t('global.selected_label')"
									:options="all_actes_type"
									@search-change="asyncFind"
									:internal-search="false"
									:searchable="true"
									:allow-empty="false"
									:loading="loading_acte"
									:show-labels="false"
									group-values="actestypes"
									group-label="actesgroupe_label"
									:group-select="false"
									:multiple="true"
								>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<DateRange
									:start.sync="start_date"
									:end.sync="end_date"
									@submit="loadHorseWithoutAct"
								/>
							</div>
						</div>
					</div>
					<div class="box">
						<div class="row">
							<div class="col-12">
								<LoadingSpinner v-if="loading" class="col-12" />

								<CustomTable
									v-else
									id_table="horse_without_act"
									primaryKey="horse_id"
									:hrefsRoutes="config_table_hrefs"
									:items="horses"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
    import Horse from "@/mixins/Horse.js";
    import Lieux from "@/mixins/Lieux.js"
    import Actes from "@/mixins/Actes.js"
    import Navigation from '@/mixins/Navigation.js'

	export default {
		name: "HorseWithoutAct",
		mixins: [Horse, Lieux, Actes, Navigation],
		data () {
			return {
				categorie_selected: [],
            	all_categorie: [],
            	load_categorie: true,
            	residence_selected: null,
            	residences: [],
            	laoding_residence: true,
            	acte_type_selected: [],
            	all_actes_type: [],
            	loading_acte: true,
            	start_date: new Date(new Date().setDate(new Date().getDate() - 90)),
				end_date: new Date(),
				horses: [],
				loading: false,
				saved_elements: null,
                config_table_hrefs: {
                    'horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse_id'
                        }
                    }
                },
				events_tab: {
					'TableAction::goToSelectHorses': this.onSelectHorses
				}
			}
		}, 
		mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
            	this.load_categorie = true
				this.all_categorie = await this.getAllCategorie()
				this.load_categorie = false

				this.laoding_residence = true
				this.residences = await this.getResidences()
				this.laoding_residence = false

				this.loading_acte = true
				this.all_actes_type = await this.getActesGroupes(true, true)
				this.loading_acte = false
			},

			async loadHorseWithoutAct() {
				this.loading = true
				const actes_types_ids = this.acte_type_selected.map(act => act.actestype_id)
				const categories_ids = this.categorie_selected.map(cat => cat.categorie_id)
				this.horses = await this.getHorseWithoutActs(categories_ids, this.residence_selected, actes_types_ids, this.start_date, this.end_date)
				this.loading = false
			},

			asyncFind (query) {
                if(this.saved_elements === null){
                    this.saved_elements = this.deppCloneObj(this.all_actes_type)
                }

                if(query == ""){
                    this.all_actes_type = this.saved_elements
                    return true
                }

                this.all_actes_type = []
                
                let current_elements = this.deppCloneObj(this.saved_elements)
                let tab_elems = []

                tab_elems = current_elements.filter((elem, key) => {
					
					if(elem.actesgroupe_label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
						return true
                    }
                    
                    const actes_type = elem.actestypes.filter((elem_actetypes) => {
						
						if(elem_actetypes.actestype_label.toLowerCase().indexOf(query.toLowerCase()) > -1 || elem_actetypes.actestype_code.toLowerCase().indexOf(query.toLowerCase()) > -1){
							return true
						}

						return false
                    })

                    elem.actestypes = actes_type

                    if(elem.actestypes.length == 0) {
                        return false
                    }

                    return true
                })

                this.all_actes_type = tab_elems
            }
		},
		components: {
			DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
